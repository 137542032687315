<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <!-- <h2 class="brand-text text-primary ml-1">BoxStop</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <div>
          <b-overlay
            :show="showForgotOverlay"
            rounded="sm"
            variant="dark"
          >
            <div>
              <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
              >
                <b-card-title
                  title-tag="h2"
                  class="font-weight-bold mb-1"
                >
                  Forgot Password? 🔒
                </b-card-title>
                <b-card-text class="mb-2">
                  Enter your phone number or email and we'll send you
                  instructions to reset your password
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                  <b-form
                    class="auth-forgot-password-form mt-2"
                    @submit.prevent="validationForm"
                  >
                    <b-form-checkbox
                      v-model="usePhone"
                      value="true"
                      class="form-control-sm"
                    >
                      {{
                        usePhone ? "Unheck to use Email" : "Check to use Phone"
                      }}
                    </b-form-checkbox>
                    <b-form-group
                      :label="usePhone ? 'Phone' : 'Email'"
                      label-for="forgot-password-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="usePhone ? 'Phone' : 'Email'"
                        :rules="usePhone ? 'required' : 'required|email'"
                      >
                        <b-form-input
                          id="forgot-password-email"
                          v-model="userId"
                          :state="errors.length > 0 ? false : null"
                          name="forgot-password-email"
                          :placeholder="
                            usePhone ? '07030000000' : 'john@example.com'
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="showOtp"
                      label="OTP"
                      label-for="forgot-password-otp"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="OTP"
                        rules="required"
                      >
                        <b-form-input
                          id="forgot-password-otp"
                          v-model="otp"
                          :state="errors.length > 0 ? false : null"
                          name="forgot-password-otp"
                          placeholder="123321"
                        />
                        <small class="text-danger">{{ errors[1] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      type="submit"
                      :variant="showOtp ? 'secondary' : 'primary'"
                      block
                    >
                      {{ showOtp ? "Resend OTP" : "Send OTP" }}
                    </b-button>

                    <b-button
                      v-show="showOtp"
                      type="button"
                      variant="primary"
                      block
                      @click="verifyUser"
                    >
                      Verify
                    </b-button>
                  </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                  <b-link :to="{ name: 'login' }">
                    <feather-icon icon="ChevronLeftIcon" /> Back to login
                  </b-link>
                </p>
              </b-col>
            </div>
          </b-overlay>
        </div>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import { apiUrl } from '@/constants/config'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      userId: '',
      sideImg: require('@/assets/images/pages/boxstop-hero-illustration-01.png'),
      // validation
      required,
      email,
      usePhone: true,
      showOtp: false,
      otp: '',
      showForgotOverlay: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        this.sideImg = require('@/assets/images/pages/boxstop-hero-illustration-01.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.showOtp = false
      this.showForgotOverlay = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = this.usePhone
            ? { phone: this.userId }
            : { email: this.userId }
          axios({
            url: `${apiUrl}forgot-password`,
            data,
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
          })
            .then(response => {
              // console.log(response.data.message);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    `Success: ${
                      response.data.message
                    } \nPlease check your ${
                      this.usePhone ? 'Phone' : 'Email'
                    } for OTP.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.showOtp = true
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    `Error: ${
                      error.response.status == 404
                        ? `Invalid ${this.usePhone ? 'Phone' : 'Email'}`
                        : error.response.status == 422
                          ? error.response.data.message
                          : error.message}`,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.showForgotOverlay = false
            })
        } else {
          this.showForgotOverlay = false
        }
      })
    },
    verifyUser() {
      this.showForgotOverlay = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = this.usePhone
            ? { phone: this.userId, otp: this.otp }
            : { email: this.userId, otp: this.otp }
          axios({
            url: `${apiUrl}verify-otp`,
            data,
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
          })
            .then(response => {
              // console.log(response.data.message);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    `Success: ${
                      response.data.message
                    } \nPlease check your ${
                      this.usePhone ? 'Phone' : 'Email'
                    } for OTP.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              data.token = response.data.token
              this.$router.push({
                name: 'reset-password',
                params: data,
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    `Error: ${
                      error.response.status == 404
                        ? `Invalid ${this.usePhone ? 'Phone' : 'Email'}`
                        : error.response.status == 422
                          ? error.response.data.message
                          : error.message}`,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.showForgotOverlay = false
            })
        } else {
          this.showForgotOverlay = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
