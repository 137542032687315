<template>
  <img
    :src="logo"
    width="150px"
  >
</template>
<script>
import { apiUrl } from '@/constants/config'
import axios from 'axios'

const logo = require('@/assets/images/logo/logo.png')

export default {
  data() {
    return {
      logo,
    }
  },
  mounted() {
    const { slug } = this.$route.params
    if (slug) this.setLogo(slug)
  },

  methods: {
    setLogo(slug) {
      axios({
        url: `${apiUrl}search/${slug}`,
        data: {},
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(response => {
          const { avatar } = response.data.partner

          if (avatar) {
            this.logo = avatar
          }
        })
        .catch(() => {
          this.$router.push({ name: 'daas' })
        })
        .finally(() => {
        })
    },
  },
}
</script>
